import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { StoreModule } from '@store/store.module'
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomPreloaderService } from '@core/services/custom-preloader/custom-preloader.service';
import { CacheRouteReuseStrategy } from '@core/services/cache-route-reuse-strategy/cache-route-reuse-strategy';
import { HomeComponent } from './feature/home/home/home.component';
import { HomeModule } from './feature/home/home.module';
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';

registerLocaleData(localeEnGB);

const appRoutes: Routes = [

  {
    path: '',
    component: HomeComponent,
    data: { website: 'p7ds' },
  },
  {
    path: 'legal',
    children: [
      {
        path: 'competition-terms-conditions',
        loadChildren: () => import('./feature/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
      },
      {
        path: 'privacy-and-cookie-policy',
        loadChildren: () => import('./feature/privacy-policy-and-cookie/privacy-policy-and-cookie.module').then(m => m.PrivacyPolicyAndCookieModule),
      },
      {
        path: 'privacy-policy',
        redirectTo: 'privacy-and-cookie-policy',
      },
      {
        path: 'cookie-policy',
        redirectTo: 'privacy-and-cookie-policy',
      },
      {
        path: 'responsible-playing',
        loadChildren: () => import('./feature/responsible-playing/responsible-playing.module').then(m => m.ResponsiblePlayingModule),
      },
    ]
  },
  {
    path: 'sms-optout/:slug',
    loadChildren: () => import('./feature/sms-optout/sms-optout.module').then(m => m.SmsOptoutModule),
  },
  {
    path: 'sms-optout',
    loadChildren: () => import('./feature/sms-optout/sms-optout.module').then(m => m.SmsOptoutModule),
  },
  {
    path: 'security',
    loadChildren: () => import('./feature/security/security.module').then(m => m.SecurityModule),
  },
  {
    path: 'install-app-for-android',
    loadChildren: () => import('./feature/install-app-landing/install-app-landing.module').then(m => m.InstallAppLandingModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./feature/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'how-to-play',
    loadChildren: () => import('./feature/how-to-play/how-to-play.module').then(m => m.HowToPlayModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./feature/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'my-account',
    loadChildren: () => import('./feature/my-account/my-account.module').then(m => m.MyAccountModule),
  },
  {
    path: 'basket',
    loadChildren: () => import('./feature/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./feature/checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'winners',
    loadChildren: () => import('./feature/competition-winners/competition-winners.module').then(m => m.CompetitionWinnersModule),
  },
  {
    path: 'competitions',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'competitions' },
  },
  {
    path: 'mobile-app',
    redirectTo: 'competition-category/mobile-app',
  },
  {
    path: 'tackle',
    redirectTo: 'tech-and-cash',
  },
  {
    path: 'automated-draws-explained',
    loadChildren: () => import('./feature/automated-draws-explained/automated-draws-explained.module').then(m => m.AutomatedDrawsExplainedModule),
  },
  {
    path: 'competition-category/mobile-app',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'app-only' },
  },
  {
    path: 'upcoming-competitions',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'upcoming-competitions' },
  },
  {
    path: 'todays-competitions',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'todays-competitions' },
  },
  {
    path: 'today',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'todays-competitions' },
  },
  {
    path: 'tomorrow',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'tomorrows-competitions' },
  },
  {
    path: 'just-launched',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'just-launched' },
  },
  {
    path: 'monday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'monday-draws' },
  },
  {
    path: 'tuesday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'tuesday-draws' },
  },
  {
    path: 'wednesday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'wednesday-draws' },
  },
  {
    path: 'thursday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'thursday-draws' },
  },
  {
    path: 'friday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'friday-draws' },
  },
  {
    path: 'saturday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'saturday-draws' },
  },
  {
    path: 'sunday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'sunday-draws' },
  },
  {
    path: 'sunday-draws',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'sunday-draws' },
  },
  {
    path: 'friday-draws',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'friday-draws' },
  },
  {
    path: 'ending-soon',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'ending-soon' },
  },
  {
    path: 'draw-results',
    loadChildren: () => import('./feature/draw-results/draw-results.module').then(m => m.DrawResultsModule),
  },
  {
    path: 'competition/:slug',
    loadChildren: () => import('./feature/product/product.module').then(m => m.ProductModule),
    data: { preloadPriority: 90 },
  },
  {
    path: 'deletion-confirmed',
    loadChildren: () => import('./feature/deletion-confirmed/deletion-confirmed.module').then(m => m.DeletionConfirmedModule),
  },
  {
    path: 'deletion-info',
    loadChildren: () => import('./feature/deletion-info/deletion-info.module').then(m => m.DeletionInfoModule),
  },
  {
    path: 'worldwide-shipping',
    loadChildren: () => import('./feature/worldwide/worldwide.module').then(m => m.WorldwideModule),
  },
  {
    path: 'user-upload/:slug',
    loadChildren: () => import('./feature/user-upload/user-upload.module').then(m => m.UserUploadModule),
  },
  {
    path: 'upload-prompt/:slug',
    loadChildren: () => import('./feature/upload-prompt/upload-prompt.module').then(m => m.UploadPromptModule),
  },
  {
    path: 'prize-delivery/:slug',
    loadChildren: () => import('./feature/prize-delivery/prize-delivery.module').then(m => m.PrizeDeliveryModule),
  },

  {
    path: ':slug',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { preloadPriority: 90 },
  },
  {
    path: '**',
    loadChildren: () => import('./feature/error404/error404.module').then(m => m.Error404Module),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule,
    RouterModule.forRoot(
      appRoutes,
      {
    preloadingStrategy: CustomPreloaderService,
    anchorScrolling: 'enabled'
}
    ),
    CoreModule,
    HomeModule,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    }, { provide: LOCALE_ID, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
